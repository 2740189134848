import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
    state: () => {
        return { userID: localStorage.getItem('userId') }
    },
    actions: {
        login(userId) {
            this.userID = userId
            localStorage.setItem('userId', userId)
        },
        logout() {
            this.userID = false
            localStorage.removeItem('userId')
        }
    },
})