const config = {
    API_HOST: process.env.VUE_APP_API_HOST,
    PUSHER_KEY: process.env.VUE_APP_PUSHER_KEY,
    WS_HOST: process.env.VUE_APP_WS_HOST,
    WS_PORT: process.env.VUE_APP_WS_PORT,
    WSS_PORT: process.env.VUE_APP_WSS_PORT,
    WS_AUTH_ENDPOINT: process.env.VUE_APP_AUTH_ENDPOINT,
};

export default config;
