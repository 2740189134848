import axios from "axios";
import config from "../config.js";
import { token } from "@/helpers";
export default class AuthController {
    constructor() {}

    login(data) {
        return axios({
            method: 'post',
            url: config.API_HOST + '/login',
            data: data
        })
    }

    registration(data) {
        return axios({
            method: 'post',
            url: config.API_HOST + '/registration',
            data: data
        })
    }

    me() {
        return axios({
            method: 'get',
            url: config.API_HOST + '/me',
            headers: token()
        })
    }

    logout() {
        return axios({
            method: 'post',
            url: config.API_HOST + '/logout',
            headers: token()
        })
    }

}
