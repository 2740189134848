import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import VueProgressBar from "@aacassandra/vue3-progressbar";
import Echo from "laravel-echo";
import Pusher from 'pusher-js'
import axios from 'axios';
import {token} from "@/helpers";
import config from "@/config";
import { createPinia } from 'pinia'

const options = {
    color: "red",
    failedColor: "#874b4b",
    thickness: "5px",
    transition: {
        speed: "0.2s",
        opacity: "0.9s",
        termination: 300,
    },
    autoRevert: true,
    location: "top",
    inverse: false,
};


window.axios = axios;
window.Pusher = Pusher;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: config.PUSHER_KEY,
    cluster: 'eu',
    wsHost: config.WS_HOST,
    wsPort: config.WS_PORT,
    wssPort: config.WSS_PORT,
    forceTLS: false,
    enabledTransports: ['ws', 'wss'],
    auth: {
        headers: token()
    },
    authEndpoint: config.WS_AUTH_ENDPOINT,
});

const pinia = createPinia()

createApp(App).use(VueProgressBar, options).use(router).use(pinia).use(router).mount('#app')
