import { createRouter, createWebHistory } from 'vue-router'

export const routes = [
  {
    name: 'login',
    path: '/login',
    component: () => import('../components/Auth/Login.vue')
  },
  {
    name: 'registration',
    path: '/registration',
    component: () => import('../components/Auth/Registration.vue')
  },
  {
    name: 'logout',
    path: '/logout',
    component: () => import('../components/Auth/Logout.vue')
  },
  {
    path: '/dialogues/:id',
    name: 'dialogues',
    component: () => import('../components/Chat/ChatMessages.vue')
  },
  {
    name: 'chats',
    path: '/chats',
    component: () => import('../components/Chat/Chats.vue')
  },
  {
    name: 'user',
    path: '/users/:id',
    component: () => import('../components/User/User.vue')
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
